var default_width = window.innerWidth;//アクセスした時の横幅を取得 スクロールバーを含めた値
var resize_width = window.innerWidth;
var break_point = 1000;
$(function() {
	var default_height = $(document).innerHeight();//アクセスした時の高さを取得
  /*------------------------*/
  //リンクの範囲を広くする
  /*------------------------*/
  $(".widelink").on('click',function(){
    window.location=$(this).find("a").attr("href");
    return false;
	});

  //スムーズスクロール（ページ内リンク）
  var headerHeight = $('.header').outerHeight();
  var urlHash = location.hash;
  if(urlHash) {
      $('body,html').stop().scrollTop(0);
      setTimeout(function(){
          var target = $(urlHash);
          var position = target.offset().top - headerHeight - 50;
          $('body,html').stop().animate({scrollTop:position}, 600,"swing");
      }, 100);
  }

  $("a[href^='#'], .js-scroll").on("click", function () {
    var f = 600;
    var e = $(this).attr("href");
    var g = $(e == "#" || e == "" ? "html" : e);
    var h = g.offset().top - headerHeight - 50;
    $("html, body").animate({
      scrollTop: h
    }, f, "swing");
    return false
  });

  //qa開閉
  $(".qTtl").on('click',function() {
    if (default_width <= break_point) {
      $(this).next(".aText").slideToggle(300);
      $(this).toggleClass("active");
    }
  });

  //scroll時のヘッダー変化
  var $win = $(window),
      $header = $('.header'),
      headerPos = $header.offset().top,
      scrollClass = 'scroll';
  $win.on('load scroll', function() {
    if (default_width >= break_point) {
      var value = $(this).scrollTop();
      if ( value > headerPos ) {
        $header.addClass(scrollClass);
      } else {
        $header.removeClass(scrollClass);
      }
    }
  });

  //inview（画面内に入ったら発火する）
  var ignition = function() { 
    $('.js-inview').on('inview', function(event, isInView, visiblePartX, visiblePartY) {
      $(this).stop().addClass('active');
    });
  };
  var bandText = function() { 
    $('.passing').on('inview', function(event, isInView, visiblePartX, visiblePartY) {
      $(this).stop().addClass("move");
    });
  };
  setTimeout(function() {
    ignition();
  },1000);
  setTimeout(function() {
    bandText();
  },300);

  /*----------------------------------------------------*/
  /* [PC][SP]UseragentでJSを切り替え
  /*----------------------------------------------------*/
  var getDevice = (function() {
  var ua = navigator.userAgent;
    if (!(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)) {
      /*-------------------------------------------------------------------------*/
      /* PCの時
      /*-------------------------------------------------------------------------*/
      $(".widelink").hover(
        function(e) {
            $(this).addClass("hover");
        },
        function(e) {
            $(this).removeClass("hover");
            
        }
      );
    }
  })();
  

	/*------------------------*/
	//バーガーメニュー表示・非表示
	/*------------------------*/
	//バーガーボタンクリック
	$(".burger").on('click',function() {
		default_width = window.innerWidth;//クリックされた時の数値をdefault_widthに設定
		if (default_width <= break_point) {
			$(".burger").toggleClass("is-open");
      $(".gNav").toggleClass("active");
			if ($(this).hasClass("is-open")) { //バーガーメニューが開かれた状態か
        $(".headerNav__cover").removeClass("hide").addClass("show");
			} else {
        $(".headerNav__cover").addClass("hide").removeClass("show");
			}
		}
	});
  //カバーをクリック
	$(".headerNav__cover,.gNav a").on('click',function() {
    if (default_width <= break_point) {
      $(".gNav").removeClass("active");
  		$(".burger").removeClass("is-open");
      $(".headerNav__cover").addClass("hide").removeClass("show");
    }
  });
  $(".pullDownHover").on('click',function(){
		if (default_width <= break_point) {
			$(this).toggleClass('active');
			$(this).find(".pullDown").slideToggle(400);
		}
  });
  
	/*------------------------*/
	//リサイズ時の処理（リサイズした瞬間に走る）
	/*------------------------*/
	$(window).on('resize',function() {
		if (default_width == window.innerWidth){//アクセスした時と、リサイズした時のウィンドウ幅が同じかどうかを判定
			return;//アクセスした時と、リサイズした時のウィンドウ幅が同じだった時に処理を抜ける
		} else {
			default_width = window.innerWidth;//リサイズ処理がされた場合、default_widthの数値を更新
			resize_width = window.innerWidth;//リサイズが完了したらウィンドウサイズの更新
			if (resize_width <= break_point) {
				/*------------------------*/
				//スマホ処理（リサイズ時）
				/*------------------------*/
        //バーガーメニューを開いたままリサイズされた時の処理
        $(".gNav,.pullDown").css("display","").removeClass("active");
				$(".burger").removeClass("is-open");
				$(".headerNav__cover").addClass("hide").removeClass("show");
			} else {
				/*------------------------*/
				//PC処理（リサイズ時）
				/*------------------------*/
        $(".gNav,.pullDown").css("display","block");
			}
		}
	});

	/*------------------------*/
	//リサイズ完了時点処理（完了するまで処理されない）
	/*------------------------*/
	var timer = false;
	$(window).on('resize',function() {
		if (timer !== false) {
				clearTimeout(timer);
		}
		timer = setTimeout(function() {
			resize_width = $(window).innerWidth();//リサイズが完了したらウィンドウサイズの更新
			if (resize_width <= break_point) {
				/*------------------------*/
				//スマホ処理（リサイズ完了時）
        /*------------------------*/
        $header.removeClass(scrollClass);
			} else {
				/*------------------------*/
				//PC処理（リサイズ完了時）
				/*------------------------*/
			}
		}, 200);
	});
});

$(window).on('load',function() {
  $("body").addClass("load");
});
/*------------------------*/
//関数
/*------------------------*/
/*新社屋　スライダー*/
var sliderOffice = function() {
	$(function() {
		//slider設置
    var $slider = $('.slider--newOffice');
    var slideCount = $('.slider--newOffice .listItem').length;
    if (slideCount >= 2) {
      $slider.slick({
        accessibility: false,
        arrows: false,
        dots: true,
        slidesToShow: 1,
        draggable: false,
        fade: true,
        autoplay: true,
        autoplaySpeed: 2500,
        speed: 1000
      });
    }
	});
}